@import 'styles/variables.scss';

@font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: swap;
    font-style: oblique 0deg 10deg;
    src: url('fonts/Inter.var.woff2?v=3.18') format('woff2');
}

html {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;

    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }

    @media only screen and (min-width: 2560px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 2880px) {
        font-size: 20px;
    }
}

html, body, #root {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    line-height: normal;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: var(--font-size-xl);
}

h2 {
    font-size: var(--font-size-lg);
}

h3 {
    font-size: var(--font-size-md);
}

h4 {
    font-size: var(--font-size-sm);
}

h5 {
    font-size: var(--font-size-xs);
}

h6 {
    font-size: var(--font-size-xs);
}

p {
    font-size: var(--font-size-md);
}

h1, h2, h3, h4, h5, h6 {
    color: inherit;
    margin: 0.5em 0em;
    font-weight: 400;
}

ul {
    padding-inline-start: var(--padding-lg);
}

li {
    margin-bottom: 0.5em;
}

.ant-btn {
    border: none;
    box-shadow: none;
    font-size: var(--font-size-sm);
    height: auto;
    padding: var(--padding-sm) var(--padding-lg);
}

.ant-btn-link {
    padding: var(--padding-sm);
}

.ant-checkbox-wrapper {
    font-size: var(--font-size-sm);
}

.ant-drawer-header {
    background-color: var(--page-header-background-color);

    .ant-drawer-title {
        color: var(--text-color-primary);
    }
}

.ant-drawer-wrapper-body {
    background-color: var(--input-background-color);
}

.ant-drawer-header,
.ant-drawer-footer {
    border-color: var(--border-color);
}

.ant-input {
    font-size: var(--font-size-sm);
}

.ant-input-number-input-wrap {
    font-size: var(--font-size-sm);
}

.ant-picker-input > input {
    font-size: var(--font-size-sm);
}

.ant-select-selector {
    background-color: var(--input-background-color) !important;
    border-color: var(--border-color) !important;
    height: 44px !important;
    padding: 8px !important;
    padding-top: 6px !important;
}

.ant-select-item,
.ant-select-dropdown,
.ant-select-item-option-selected {
    background-color: var(--input-background-color) !important;
}

.ant-select-item,
.ant-select-item-option-selected,
.ant-select-selection-placeholder,
.ant-select-selection-item {
    color: var(--input-foreground-color) !important;
    font-size: var(--font-size-sm);
}

.ant-select-item-option-active {
    background-color: var(--input-background-color-disabled) !important;
    color: var(--input-foreground-color-disabled) !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    font-size: var(--font-size-sm);
    height: 2rem;
}

.ant-table {
    font-size: var(--font-size-sm);
}

.ant-table-tbody > tr > td {
    padding: var(--padding-lg);
}

.ant-tag {
    font-size: var(--font-size-sm);
    padding: var(--padding-xs) var(--padding-lg);
}

@media print {
    body * {
        visibility: hidden;
    }

    .printable, .printable * {
        visibility: visible;
    }

    .printable {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}